
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import './style.css'
import moment from "moment";
import axios from "axios";

const SearchSection = (props) => {

    const [startDate,  setStartDate] = useState(new Date());
    const [startDates,  setStartDates] = useState(new Date());
    const [name,  setName] = useState("");
    const [phone,  setPhone] = useState("");


   
    const [inputField,changeInputField]=useState(

        {
        "name":"",
        "phone":""
       }
    
      )

      const inputHandler=(event)=>{
        changeInputField({...inputField,[event.target.name]:event.target.value})
      }

      function formatDate(date = new Date()) {
        var day, month, year;
      
        year = date.getFullYear();
        month = date.getMonth() + 1;
        day = date.getDate();
        
        if (month < 10) {
          month = '0' + month;
        }
      
        if (day < 10) {
          day = '0' + day;
        }
        
        return day + '/' + month + '/' + year;
      }
      
      



    const SubmitHandler = (e) =>{
        // console.log(startDate)
        // console.log(startDates)
        // console.log(inputField)

        let checkInDate= moment(startDate).format("DD/MM/YYYY");
        let checkOutDate= moment(startDates).format("DD/MM/YYYY");


        // const checkOutday = new Date(startDate);
        // CheckOutddmmyyyy = formatDate(checkOutday);
        // console.log(CheckOutddmmyyyy);


        const data={"checkin":checkInDate,"checkout":checkOutDate,"name":inputField.name,"contact":inputField.phone}
       //console.log(data)
 

       axios.post("https://api.inchimalahomes.com/api/booking",data).then(
        (response)=>{
            alert("Booking Enquiry has sent to Admin. Will Contact You shortly !!! ")
        }
       ).catch()

        e.preventDefault()

     }

    return(
        <div className={`wpo-select-section ${props.selectClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="wpo-select-wrap">
                            <div className="wpo-select-area">
                                <form onSubmit={SubmitHandler} className="clearfix">
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>Check - In</span>
                                        <div className="form-group">
                                            <div id="filterDate">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span><i className="fi flaticon-calendar"></i>Check - out</span>
                                        <div className="form-group">
                                            <div id="filterDate2">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                    <DatePicker selected={startDates} onChange={(date) => setStartDates(date)} />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> Name</span>

                                        <div className="form-group">
                                            <div id="filterDate2">
                                                <div className="input-group date" data-date-format="dd.mm.yyyy">
                                                <input class="form-control" name="name" value={inputField.name} onChange={inputHandler} required />
                                                    <div className="input-group-addon">
                                                        <span className="ti-angle-down"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        {/* <select className="select wide">
                                            <option>02</option>
                                            <option>01</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                        </select> */}
                                    </div>
                                    <div className="select-sub">
                                        <span> <i className="fi flaticon-user"></i> Contact Number</span>

                                        
                                        <input class="form-control" name="phone" value={inputField.phone} onChange={inputHandler}  required/>
                                        {/* <select className="select wide">
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                            <option>06</option>
                                        </select> */}
                                    </div>
                                    <div className="select-sub">
                                        <button className="theme-btn-s2" type="submit"> Submit </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SearchSection;