import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import classnames from 'classnames';
import './style.css'
import {Link} from 'react-router-dom'
import rm1 from '../../images/room/LST_Pic__WhatsApp Image 2023-12-11 at 1.23.24 PM.jpeg'
import rm2 from '../../images/room/LST_Pic__WhatsApp Image 2023-12-11 at 1.23.25 PM.jpeg'
import rm3 from '../../images/room/LST_Pic__WhatsApp Image 2023-12-11 at 1.23.33 PM.jpeg'
import rm4 from '../../images/room/tree_2.jpg'
import rm5 from '../../images/room/tree_3.jpg'
import rm6 from '../../images/room/bbq.jpg'
import rm7 from '../../images/room/camp.jpg'
import rm8 from '../../images/room/bbq1.jpg'
import rm9 from '../../images/room/b1.jpg'
import rm10 from '../../images/room/b2.jpg'


const RoomSection = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }


    const Room = [

        {
            RoomImg:rm1,
            RoomHeading:"Kitchen",
            RoomCount:'Kitchen',
            Des:"Unleash your inner chef in our well-equipped kitchen, designed to inspire creativity and culinary excellence – a place where the joy of cooking is as much about the process as it is about the results",
            Price:"$142",
            Link:"/room-single",
        },
        {
            RoomImg:rm2,
            RoomHeading:"Dining Room",
            RoomCount:'Dining Room',
            Des:"Immerse yourself in the perfect blend of exquisite flavors and timeless elegance in our dining room – where culinary craftsmanship meets stylish ambiance for a truly unforgettable experience.",
            Price:"$142",
            Link:"/room-single",
        },
        {
            RoomImg:rm3,
            RoomHeading:"Bed Room",
            RoomCount:'3 BedRooms',
            Des:"Experience the epitome of luxury living in our spacious 3 BHK bedrooms – a harmonious blend of contemporary design and tranquil comfort, creating a haven for restful nights and rejuvenating mornings.",
            Price:"$142",
            Link:"/room-single",
        },

    ]

    const Room2 = [

        {
            RoomImg:rm4,
            RoomHeading:"Tree House",
            RoomCount:'Tree House',
            Des:"Immerse yourself in the enchanting ambiance of our treehouse, where you can not only enjoy the breathtaking views but also prepare and savor delicious meals amidst nature",
            Price:"$142",
            Link:"/room-single",
        },
        {
            RoomImg:rm5,
            RoomHeading:"Tree House",
            RoomCount:'Tree House',
            Des:"Discover a unique culinary experience at our treehouse retreat, where you can create and relish delightful meals against the backdrop of lush greenery and the soothing sounds of nature",
            Price:"$142",
            Link:"/room-single",
        },
       

    ]


    const Room3 = [

        {
            RoomImg:rm6,
            RoomHeading:"CampFire",
            RoomCount:'BBQ Space',
            Des:"Gather around the warmth of our inviting campfire area, where the flickering flames set the stage for delightful culinary adventures, soulful music, and spontaneous dance under the starlit sky.",
            Price:"$142",
            Link:"/room-single",
        },
        {
            RoomImg:rm7,
            RoomHeading:"CampFire",
            RoomCount:'CampFire',
            Des:"Experience the magic of a roaring campfire, providing the perfect backdrop for food preparation, harmonious tunes, and impromptu dances, creating memories that will last a lifetime",
            Price:"$142",
            Link:"/room-single",
        },
        {
            RoomImg:rm8,
            RoomHeading:"BBQ",
            RoomCount:'CampFire',
            Des:"Embark on a culinary adventure at our campfire BBQ area, where the tantalizing aroma of grilled delights mingles with the harmonious tunes of campfire music, creating a sensory feast for all",
            Price:"$142",
            Link:"/room-single",
        }
       

    ]



    const Room4 = [

        {
            RoomImg:rm9,
            RoomHeading:"Balcony",
            RoomCount:'Tea Plantation View',
            Des:"Wake up to the mesmerizing panorama of lush tea plantations right outside your window at our homestay, where the beauty of nature unfolds in every sip of your morning tea",
            Price:"$142",
            Link:"/room-single",
        },
        {
            RoomImg:rm10,
            RoomHeading:"Balcony",
            RoomCount:'Tea Plantation View',
            Des:"Our homestay offers a picturesque retreat surrounded by sprawling tea plantations, providing guests with an immersive experience of tranquility and the soothing ambiance of verdant landscapes.",
            Price:"$142",
            Link:"/room-single",
        },

       

    ]

    return(
        <section className={`Room-area section-padding ${props.rClass}`}>
            <div className="Room-section">
                <div className="container">
                    <div className="col-12">
                        <div className="wpo-section-title">
                            <span>Our Property</span>
                            <h2>Discover Our Property</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12 sortable-gallery">
                            <div className="gallery-filters">
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                          Rooms 
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            Tree House 
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggle('3'); }}
                                        >
                                           Campfire &  BBQ
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' })}
                                            onClick={() => { toggle('4'); }}
                                        >
                                           Balcony
                                        </NavLink>
                                    </NavItem>

                                    {/* 
                                    
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' })}
                                            onClick={() => { toggle('4'); }}
                                        >
                                            OutDoor
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '5' })}
                                            onClick={() => { toggle('5'); }}
                                        >
                                            Others
                                        </NavLink>
                                    </NavItem> */}
                                    
                                </Nav>
                            </div>
                            <div className="gallery-container">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img height="440px" width="370px" src={room.RoomImg} alt="" className="img img-responsive"/>
                                                        <div className="room-text-show">
                                                            <h2>{room.RoomHeading}</h2>
                                                        </div>
                                                        <div className="room-text-hide">
                                                            <h2>{room.RoomHeading}</h2>
                                                            <span>{room.RoomCount}</span>
                                                            <p>{room.Des}</p>
                                                            {/* <small>From: <span>{room.Price}</span> / Night</small> */}
                                                            {/* <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        {Room2.map((room, rm) => (
                                            <div className="grid" key={rm}> 
                                                <div className="room-item">
                                                    <img height="440px" width="370px" src={room.RoomImg} alt="" className="img img-responsive"/>
                                                    <div className="room-text-show">
                                                        <h2>{room.RoomHeading}</h2>
                                                    </div>
                                                    <div className="room-text-hide">
                                                        <h2>{room.RoomHeading}</h2>
                                                        <span>{room.RoomCount}</span>
                                                        <p>{room.Des}</p>
                                                        {/* <small>From: <span>{room.Price}</span> / Night</small>
                                                        <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>


                                    <TabPane tabId="3">
                                        {Room3.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img height="440px" width="370px"  src={room.RoomImg} alt="" className="img img-responsive"/>
                                                        <div className="room-text-show">
                                                            <h2>{room.RoomHeading}</h2>
                                                        </div>
                                                        <div className="room-text-hide">
                                                            <h2>{room.RoomHeading}</h2>
                                                            <span>{room.RoomCount}</span>
                                                            <p>{room.Des}</p>
                                                            {/* <small>From: <span>{room.Price}</span> / Night</small>
                                                            <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </TabPane>

                                    <TabPane tabId="4">
                                        {Room4.map((room, rm) => (
                                            <div className="grid" key={rm}> 
                                                <div className="room-item">
                                                    <img height="440px" width="370px" src={room.RoomImg} alt="" className="img img-responsive"/>
                                                    <div className="room-text-show">
                                                        <h2>{room.RoomHeading}</h2>
                                                    </div>
                                                    <div className="room-text-hide">
                                                        <h2>{room.RoomHeading}</h2>
                                                        <span>{room.RoomCount}</span>
                                                        <p>{room.Des}</p>
                                                        {/* <small>From: <span>{room.Price}</span> / Night</small>
                                                        <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>

                                    {/* 
                                    
                                    <TabPane tabId="5">
                                        {Room.map((room, rm) => (
                                                <div className="grid" key={rm}> 
                                                    <div className="room-item">
                                                        <img src={room.RoomImg} alt="" className="img img-responsive"/>
                                                        <div className="room-text-show">
                                                            <h2>{room.RoomHeading}</h2>
                                                        </div>
                                                        <div className="room-text-hide">
                                                            <h2>{room.RoomHeading}</h2>
                                                            <span>{room.RoomCount}</span>
                                                            <p>{room.Des}</p>
                                                            <small>From: <span>{room.Price}</span> / Night</small>
                                                            <Link className="theme-btn-s2" to={room.Link}>Check Availability</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </TabPane> */}
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoomSection;