import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../images/about/about.png'
import './style.css'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span>About Us</span>
                                <h2>A Best Place <br/> to Enjoy </h2>
                            </div>
                            <p>
                                
                            Set in Vagamon in the Kerala region, Inchimala homes has a balcony and garden views. This holiday home features a heated pool, a garden, barbecue facilities, free WiFi and free private parking. The accommodation offers a 24-hour front desk, a shared kitchen and luggage storage for guests.

The sustainable holiday home comes with 1 separate bedroom, 3 bathrooms, a fully equipped kitchen with a dining area, a an outdoor dining area and a flat-screen TV. The unit at the property features a hot tub and a dressing room. There is also a seating area and a fireplace.

There is a coffee shop, and packed lunches are also available.

For guests with children, the holiday home provides outdoor play equipment and a baby safety gate. Guests can also warm themselves near outdoor fireplace after a day of windsurfing.

The nearest airport is Cochin International Airport, 98 km from Inchimala homes.
                                
                                 </p>
                            <div className="btns">
                                {/* <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">More About Us</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;